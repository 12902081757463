<template>
   <div class="password_box" v-if="showPassword">
    <div class="password_content">
      <p class="title">输入密码查看该模型</p>
      <el-input
        v-model="password"
        placeholder="请输入查看密码"
        @input="pwdInput"
        maxlength="4"
        class="password_input"
      ></el-input>
      <div class="password_btn">
        <el-button type="primary" @click="checkPassword" class="btn"
          >查看</el-button
        >
      </div>
    </div>
  </div>
  <div
    ref="container"
    style="width: 100vw; height: 100vh; overflow: hidden"
  ></div>
  <div
    class="cover"
    v-if="showStartButton"
    :style="{ backgroundImage: 'url(' + previewUrl + ')' }"
  >
    <span ref="playBtn"></span>
  </div>
  <img
    v-if="customSetting.customLogo && !customSetting.hideLogo"
    :src="customSetting.customLogo"
    class="logo"
  />
  <Loading
    :percent="percent"
    theme="light"
    v-if="showLoading"
    :background="previewUrl"
  />
  <menu
    class="side_menu_wrap"
    :style="{ left: showMenu ? 0 : '-165rem' }"
    v-if="menuStat"
  >
    <div class="side_menu">
      <p class="label_row">背景</p>
      <div class="btns">
        <span
          @click.stop="changeBackground('default')"
          :class="{ active: background == 'default' }"
          >默认</span
        >
        <span
          @click.stop="changeBackground('dark')"
          :class="{ active: background == 'dark' }"
          >深色</span
        >
        <span
          @click.stop="changeBackground('light')"
          :class="{ active: background == 'light' }"
          >浅色</span
        >
      </div>
      <div class="row">
        <span>自动旋转</span><el-switch :width="30" v-model="autoRotate" />
      </div>
      <div class="row">
        <span>显示网格</span><el-switch :width="30" v-model="showWireMode" />
      </div>
      <div class="row">
        <span>显示尺寸</span><el-switch :width="30" v-model="showDimension" />
      </div>
      <p class="label_row" v-if="hadPostprocessing">后处理</p>
      <div class="btns" v-if="hadPostprocessing">
        <span
          @click.stop="togglePostprocessing(false)"
          :class="['l', !showPostprocess ? 'active' : '']"
          >无后处理</span
        >
        <span
          @click.stop="togglePostprocessing(true)"
          :class="['l', showPostprocess ? 'active' : '']"
          >有后处理</span
        >
      </div>
      <p class="label_row" v-if="!disableExplode">模型拆分</p>
      <div v-if="!disableExplode">
        <el-slider
          v-model="explodScale"
          :step="1"
          :max="100"
          :disabled="enableDrag"
          :show-tooltip="false"
        ></el-slider>
      </div>
      <p class="label_row">
        <span>模型操作</span>
      </p>
      <div class="items">
        <span @click.stop="action('drag')" :class="{ active: enableDrag }"
          ><i class="iconfont icon-yidong"></i><b>移动</b></span
        >
        <span @click.stop="action('restore')"
          ><i
            class="iconfont icon-zhongzhi1"
            :class="{ 'active-sty': acitveSty == 'restore' }"
          ></i
          ><b>复位</b></span
        >
        <span @click.stop="action('hide')"
          ><i
            class="iconfont icon-yincang1"
            :class="{ 'active-sty': acitveSty == 'hide' }"
          ></i
          ><b>隐藏</b></span
        >
        <span @click.stop="action('showAlone')"
          ><i
            class="iconfont icon-duxian"
            :class="{ 'active-sty': acitveSty == 'showAlone' }"
          ></i
          ><b>独显</b></span
        >
        <span @click.stop="action('showAll')"
          ><i
            class="iconfont icon-yanjing"
            :class="{ 'active-sty': acitveSty == 'showAll' }"
          ></i
          ><b>全显</b></span
        >
        <span @click.stop="action('transparent')"
          ><i
            class="iconfont icon-touming2"
            :class="{ 'active-sty': acitveSty == 'transparent' }"
          ></i
          ><b>透明</b></span
        >
      </div>
    </div>
    <span
      class="switch"
      @click="showMenu = !showMenu"
      v-show="percent == 100"
      v-if="menuStat"
    >
      <i v-if="showMenu" class="iconfont icon-shuangjiantouzuo"></i>
      <i v-else class="iconfont icon-shuangjiantouyou"></i>
    </span>
  </menu>
  <div class="author" v-if="shareCode || isShared">
    <a v-if="isShared" href="javascript:void(0)"
      ><img :src="authorInfo.avatar"
    /></a>
    <p>
      <span>{{ authorInfo.modelName }}</span>
      <span>{{ authorInfo.nickName }}</span>
    </p>
  </div>
  <div class="share_btn" v-if="shareCode || isShared">
    <span v-if="shareCode"
      ><a :href="domain + '/mobile'"><i class="iconfont icon-shangcheng"></i></a
    ></span>
    <span v-else
      ><a :href="domain + '/mobile/modelDetails?id=' + modelId"
        ><i class="iconfont icon-shangcheng"></i></a
    ></span>
    <span
      ><a :href="mobileDomain"><img src="@/assets/img/logo2.png" /></a
    ></span>
  </div>

  <div
    class="plans"
    v-if="planPrams.list.length > 0 && showPlan && customSetting.customizePlan"
  >
    <ul
      :class="{ disabled: isLoading }"
      :style="{ right: showMaterialPlan ? 0 : '-104rem' }"
    >
      <div style="height: 90rem">
        <li
          @click="applyPlan({ name: '默认', fileUrl: '', id: -1, preview: '' })"
          class="default"
          :class="{ selected: planPrams.selected == '默认' }"
        >
          默认
        </li>
      </div>
      <li
        v-for="item in planPrams.list"
        :key="item.id"
        @click="applyPlan(item)"
      >
        <div
          class="img"
          :style="{ backgroundImage: 'url(' + item.preview + ')' }"
          :class="{ selected: planPrams.selected == item.name }"
        ></div>
        <p v-show="planPrams.selected == item.name">{{ item.name }}</p>
      </li>
      <li class="icon" @click="showPlanEvent(false)">
        <i class="iconfont icon-jiantouyou"></i>
      </li>
    </ul>
    <div class="tip" v-show="!showMaterialPlan" @click="showPlanEvent(true)">
      <span
        ><b>3D</b> <br />
        定制</span
      >
      <i class="iconfont icon-jiantouzuo"></i>
    </div>
  </div>
  <div class="dialog dialog_right" v-show="isShowRightDialog">
    <div>
      <div class="title_box title_right">
        <span>构件详情</span
        ><i class="icon-guanbi iconfont" @click="isShowRightDialog = false"></i>
      </div>
      <div class="content_box">
        <div v-if="buildData.length > 0">
          <div class="item_box" v-for="item in buildData" :key="item.name">
            <div class="content" v-if="!item.children">
              <div class="bottom_b right_b" :title="item.name">
                {{ formatBIMName(item.name) }}
              </div>
              <div class="bottom_b" :title="item.val">
                {{ formatBIMName(item.val) }}
              </div>
            </div>
            <div v-else>
              <div class="title bottom_b" @click="item.isShow = !item.isShow">
                <i v-show="!item.isShow" class="icon-you iconfont"></i>
                <i v-show="item.isShow" class="icon-xia iconfont"></i>
                <span>{{ formatBIMName(item.title) }}</span>
              </div>
              <div
                class="content"
                v-for="cItem in item.children"
                :key="cItem.name + cItem.val"
                :style="{ display: item.isShow ? 'flex' : 'none' }"
              >
                <div
                  class="bottom_b right_b"
                  :title="formatBIMName(cItem.name)"
                >
                  {{ formatBIMName(cItem.name) }}
                </div>
                <div class="bottom_b" :title="formatBIMName(cItem.val)">
                  {{ formatBIMName(cItem.val) }}
                </div>
              </div>
            </div>
          </div>
          <i class="iconfont icon-ziyoulashen"></i>
        </div>
        <div class="flex_ct" v-else>
          <i class="iconfont icon-xiangqing"></i>
          <span>{{ notDataMsg }}</span>
        </div>
      </div>
    </div>
  </div>
  <div
    class="fixd_icon"
    v-if="isHaveComponet"
    :class="{ active_state: isShowRightDialog }"
    @click="showHideComponet"
  >
    <i class="iconfont icon-xiangqing" title="构件信息"></i>
  </div>
  <AnimationController
    v-if="animationControlsStat"
    v-show="hasAnimation && !showMaterialPlan"
    ref="animationController"
    :sceneData="sceneData"
    :style="{
      width: showMenu && menuStat ? 'calc(100vw - 165rem)' : '100%',
      opacity: mouseActive ? 0 : 1,
      right: 0,
      zIndex: 9,
    }"
  />
  <Hotspot />
  <AddElement
    :modelInfoData="modelInfoData"
    :showLoading="showLoading"
    style="width: 100vw; height: 100vh; overflow: hidden"
  ></AddElement>
</template>

<script setup>
import light from "@/assets/img/l2.jpg";
import dark from "@/assets/img/l1.jpg";
import { h } from "vue";
import axios, { Codes } from "utils/http";
import { computed, onMounted, reactive, ref, watch } from "@vue/runtime-core";
import {
  fetchModelPaths,
  isImpaction,
  sceneData,
  shareCode,
  isShared,
  modelId,
  authorInfo,
  domain,
  mobileDomain,
} from "../preview/data";
import { ElMessage } from "element-plus";
import { getSphericalCoordinates } from "@/utils/tool";
import { nextTick } from "process";
import Hotspot from "./Hotspot.vue";
import { init as initViewer, initModel, viewer } from "utils/viewer";
import { $bus, Events } from "utils/eventBus";
import Loading from "components/EditorLoading";
import AnimationController from "components/AnimationController";
import { useStore } from "vuex";
import AddElement from "./AddElement.vue";
import { useRoute } from "vue-router";
const store = useStore();


const showPassword = ref(false);
const password = ref(""); //输入密码
const passwordList = JSON.parse(localStorage.getItem("passwordList"));
const route = useRoute();
const obj = passwordList?.find((item) => item.id == modelId.value);
if (obj) {
  password.value = obj.password;
} else {
  showPassword.value = route.query.password ? true : false;
}

const pwdInput = () => {
  password.value = password.value.replace(/[^0-9a-zA-Z]/g, "");
};
const checkPassword = () => {
  if (!password.value) {
    ElMessage.error("请输入密码");
    return;
  }

  const params = {
    modelId: route.query.id,
    userId: route.query.loginId,
    modelUserId: "",
    shareCode: route.query.code,
    password: password.value,
  };
  //校验密码
  checkDetail(params);
};
const checkDetail = (params) => {
  axios
    .post(
      "/api-transaction/laozi/v3/transaction/unauth/model/modelPreview",
      params
    )
    .then((res) => {
      if (res.resp_code == Codes.Success) {
        showPassword.value = false;
        let passwordList = JSON.parse(localStorage.getItem("passwordList"));
        if (!passwordList) {
          passwordList = [];
        }
        passwordList.push({
          id: params.modelId,
          password: params.password,
        });
        localStorage.setItem("passwordList", JSON.stringify(passwordList));
        location.reload();
      } else {
        ElMessage.error(res.resp_msg);
      }
    });
};

const showMaterialPlan = computed(() => store.state.showMaterialPlan);
const animationController = ref(null),
  hasAnimation = ref(false);
const showPlan = ref(false);
const modelInfoData = ref(null);
const customSetting = reactive({
  animationControls: true,
  viewerControls: true,
  animationEntrance: true,
  autoRotation: false,
  autoStart: false,
  loopPlay: false,
  transparentBackground: true,
  customLogo: "",
  hideLogo: false,
  hotspotControls: true,
  customizePlan: true,
});
const mouseActive = ref(false);
const previewUrl = ref("");
var model, defaultPlane, defaultBackground, dimension, timer2, timer3;
var v = new AMRT.Vector3(),
  phi,
  theta,
  radius,
  c,
  boundingSphere;
const updateCamera = (t) => {
  c = boundingSphere.center;
  phi = ((2 * Math.PI) / 3) * t;
  theta = (Math.PI / 6) * (2 - t);
  radius = boundingSphere.radius * (2 + t * 2);
  v.fromArray(getSphericalCoordinates(phi, theta, radius));
  viewer.camera.position.set(v.x + c.x, v.y + c.y, v.z + c.z);
  viewer.controls.setTarget(c.x, c.y, c.z);
};
const initOperator = () => {
  viewer.operator.enabled = true;
  viewer.domElement.addEventListener(
    "mousedown",
    () => (mouseActive.value = true)
  );
  viewer.domElement.addEventListener(
    "mouseup",
    () => (mouseActive.value = false)
  );
  viewer.domElement.addEventListener(
    "mouseout",
    () => (mouseActive.value = false)
  );
  autoRotate.value = Boolean(customSetting.autoRotation);
};
const cameraAction = () => {
  const obj = { t: 1 };
  new AMRT.TWEEN.Tween(obj)
    .to({ t: 0 }, 2500)
    .onUpdate(() => {
      updateCamera(obj.t);
    })
    .repeat(0)
    .easing(AMRT.TWEEN.Easing.Quartic.InOut)
    .start()
    .onComplete(() => {
      initOperator();
      if (model.hasAnimation()) {
        animationController.value.play();
      }
    });
};
const hadPostprocessing = ref(false);
const percent = ref(null);
const isLoading = ref(true);
const showLoading = computed(
  () => percent.value != 100 && percent.value !== null
);
watch(showLoading, (value) => {
  if (!value) {
    ElMessage.warning(
      "模型数据信息过大可能会影响移动端加载，若加载失败请前往PC端查看"
    );
  }
});
const showStartButton = ref(false);
const playBtn = ref(null);
const menuStat = computed(
  () =>
    (isImpaction.value && customSetting.viewerControls) || !isImpaction.value
);
const animationControlsStat = computed(
  () =>
    (isImpaction.value && customSetting.animationControls) || !isImpaction.value
);
const isShowRightDialog = ref(false);
const buildData = ref([]);
const formatBIMName = (originName) => {
  if (originName.includes && originName.includes("$$")) {
    return originName.split("$$")[1];
  }
  return originName;
};
// 构件开关按钮
const isHaveComponet = ref(false);
const notDataMsg = ref("请选择一个构件，以查看对应属性");
// 点击构件信息开关按钮
const showHideComponet = () => {
  if (showMaterialPlan.value) {
    ElMessage.warning("请您关闭3d定制方案面板后在操作");
    return; // 必须关闭3d定制方案
  }
  isShowRightDialog.value = !isShowRightDialog.value;
  window.parent.postMessage({ gjInfofull: isShowRightDialog.value }, "*");
  showMenu.value = false;
  // 1.要判断关闭移动开关按钮
  enableDrag.value = false;
  // 重新开启清空bim信息
  // buildData.value = []
};
const showPlanEvent = (value) => {
  if (isShowRightDialog.value) {
    ElMessage.warning("请您关闭构件详情面板后在操作");
    return; // 必须关闭bim详情面板才能点击3d定制方案
  }
  store.commit("SetShowHotSpot", value);
  window.parent.postMessage({ full: value }, "*");
};
const initBIM = () => {
  if (model.isBIM) {
    isHaveComponet.value = true; // 如果有bim信息默认显示bim开关按钮
    viewer.operator.addEventListener("change", (e) => {
      console.log(e.data);
      if (enableDrag.value) return;
      // if(!isShowRightDialog.value) return // bim开关按钮关闭点击不显示详情面板
      if (e.data) {
        buildData.value = [];
        model.getComponentInfo(e.data, (data) => {
          if (data.data) {
            // isShowRightDialog.value = true
            let param = data.data;
            for (let key in param) {
              if (
                Object.prototype.toString.call(param[key]) === "[object Object]"
              ) {
                let newObj = { title: key, children: [], isShow: true };
                for (let ck in param[key]) {
                  let cobj = { name: ck, val: param[key][ck] };
                  newObj.children.push(cobj);
                }
                buildData.value.push(newObj);
              } else {
                let obj = { name: key, val: param[key] };
                buildData.value.push(obj);
              }
            }
          } else {
            // isShowRightDialog.value = false
            notDataMsg.value = "该构件无属性数据";
          }
        });
      } else {
        buildData.value = [];
        notDataMsg.value = "请选择一个构件，以查看对应属性";
      }
    });
  }
};
const v3 = new AMRT.Vector3();
const loadModel = function () {
  const updateCameraProjection = () => {
    const size = model.boundingBox.getSize();
    const max = Math.max(size.x, size.y, size.z),
      min = Math.min(size.x, size.y, size.z);
    if (max * 2 > viewer.camera.far) {
      viewer.camera.far = max * 2;
    }
    viewer.camera.near = min * 0.05;
    viewer.camera.updateProjectionMatrix();
  };
  fetchModelPaths(password.value, showPassword.value).then((data) => {
    if (isImpaction.value) {
      if (data.interactiveEdit)
        Object.assign(customSetting, data.interactiveEdit);
      if (data.buttonEdit) Object.assign(customSetting, data.buttonEdit);
      console.log(customSetting);
      customSetting.customLogo = data.customLogo;
      customSetting.hideLogo = data.hideLogo;
    }
    const { modelData } = data;
    if (modelData) {
      try {
        const res = JSON.parse(decodeURIComponent(modelData));
        previewUrl.value = res.cover || "";
      } catch (error) {
        console.warn("当前模型未设置封面");
      }
    }
    if (customSetting.autoStart) {
      showStartButton.value = true;
      nextTick(() => {
        playBtn.value.addEventListener("click", () => {
          load(data);
          showStartButton.value = false;
        });
      });
    } else {
      load(data);
    }
    function load(pathData) {
      modelInfoData.value = pathData;
      percent.value = 0;
      const { paths, size, box, textureOptions } = pathData;
      const sceneData = data.sceneData ? JSON.parse(data.sceneData) : {};
      showPlan.value = sceneData.enablePlan;
      planPrams.list = textureOptions || [];
      defaultPlane = paths.mat[0];
      if (size) dimension = size.split("x");
      const { ms } = paths;
      viewer.loadModel(paths, {
        focus: false,
        // postprocessing: false,
        onLoad: (m) => {
          initModel(m);
          if (sceneData.rotation) {
            m.rotation.fromArray(sceneData.rotation);
            m.updateMatrix();
            m.boundingBox.setFromObject(m);
          }
          $bus.emit(Events.ModelLoad);
          timer2 && clearInterval(timer2);
          percent.value = 100;
          model = m;
          window.__model = model;
          m.boundingBox.getCenter(v3);
          m.children[0].position.subVectors(m.children[0].position, v3);
          const l = m.boundingBox.getSize().length();
          if (l < 10) {
            m.scale.set(10 / l, 10 / l, 10 / l);
          } else if (l > 100) {
            m.scale.set(100 / l, 100 / l, 100 / l);
          }
          m.recalculationBound();
          m.update();
          viewer.sceneManager.fitCamera();
          boundingSphere = m.boundingSphere;
          if (isImpaction.value && !customSetting.animationEntrance) {
            setTimeout(() => {
              sceneData.view
                ? viewer.controls.setView(sceneData.view)
                : updateCamera(0);
              if (sceneData.cameraSetting) {
                autoRotate.value = sceneData.cameraSetting.autoRotation;
              }
            }, 500);
            initOperator();
          } else {
            updateCamera(1);
            if (sceneData.view) {
              setTimeout(() => {
                viewer.controls.flyTo({
                  position: sceneData.view.position,
                  target: sceneData.view.target,
                  time: 2500,
                  type: 1,
                  complete: () => {
                    initOperator();
                    if (model.hasAnimation()) animationController.value.play();
                    if (sceneData.cameraSetting) {
                      autoRotate.value = sceneData.cameraSetting.autoRotation;
                    }
                  },
                });
              }, 500);
            } else {
              setTimeout(cameraAction, 500);
            }
          }
          if (model.objects.length <= 1) disableExplode.value = true;
          initBIM();
          updateCameraProjection();
          var shadowOffsetFactor = Math.ceil(model.boundingBox.getSize().y) * 2;
          setTimeout(() => {
            if (!sceneData.shadow) return;
            viewer.sceneManager.enableShadow = sceneData.shadow.visible;
            viewer.sceneManager.shadowDecay = sceneData.shadow.decay || 1;
            viewer.sceneManager.shadowStrength = sceneData.shadow.strength / 10;
            viewer.sceneManager.shadowOffset =
              (sceneData.shadow.offset || 0) * shadowOffsetFactor;
          }, 1000);
          initCameraSetting(m);
        },
        onProgress: (pct) => {
          if (Boolean(Array.isArray(ms) && ms[0])) percent.value = pct;
        },
        onError: (error) => ElMessage.error("模型加载失败"),
        onMatLoad: () => {
          isLoading.value = false;
        },
        onAnimeLoad: () => {
          $bus.emit(Events.AnimationLoad);
          hasAnimation.value = model.hasAnimation();
          // if(isImpaction.value) animationParam.loop = Number(customSetting.loopPlay)
        },
        onSceneLoad: (config) => {
          defaultBackground = viewer.scene.background;
          if (
            (config &&
              ((config.f && config.f.v) ||
                (config.k && config.k.v) ||
                (config.s && config.s.v) ||
                (config.u && config.u.v))) ||
            (config.g && config.g.v) ||
            (config.q && config.q.v)
          )
            hadPostprocessing.value = true;
        },
      });
      if (!Boolean(Array.isArray(ms) && ms[0])) {
        timer2 = setInterval(() => {
          percent.value += 1;
          if (percent.value == 99) clearInterval(timer2);
        }, 50);
      }
    }
  });
};
const container = ref(null);
var showMenu = ref(false);
watch(showMenu, (value) => {
  $bus.emit(Events.MenuVisibleChange, value && menuStat.value);
  if (value) isShowRightDialog.value = false;
});
const showPostprocess = ref(true);

const togglePostprocessing = (value) => {
  if (value === showPostprocess.value) return;
  const res = value
    ? viewer.sceneManager.loadPostProcessing(model)
    : viewer.sceneManager.hidePostprocessing();
  showPostprocess.value = value;
  if (res && res.dof)
    ElMessage.warning({
      message: h("p", null, [
        h("span", { style: "color: #E6A23C" }, "鼠标"),
        h("b", { style: "color: #E6A23C" }, "左键双击"),
        h("span", { style: "color: #E6A23C" }, "模型聚焦"),
      ]),
    });
};
const planPrams = reactive({
  list: [],
  selected: "默认",
  show: false,
});
const applyPlan = (item) => {
  if (planPrams.selected == item.name) return;
  isLoading.value = true;
  planPrams.selected = item.name;
  enableDrag.value = false;
  model.applyMaterialFile({
    url: item.fileUrl || defaultPlane,
    onTextureLoad: () => {
      isLoading.value = false;
    },
  });
  if (item.view) viewer.controls.flyTo(JSON.parse(item.view));
};
const background = ref("default");
const changeBackground = (type) => {
  background.value = type;
  switch (type) {
    case "default":
      viewer.scene.background = defaultBackground;
      break;
    case "light":
      viewer.sceneManager.setBackground(light);
      break;
    case "dark":
      viewer.sceneManager.setBackground(dark);
      break;
  }
};
const autoRotate = ref(false);
watch(autoRotate, (value) => {
  if (animationController.value.animationParam.playing && value)
    animationController.value.stop();
  if (enableDrag.value && value) enableDrag.value = false;
  requestAnimationFrame(() =>
    value
      ? viewer.controls.startAutoRotate(0.25)
      : viewer.controls.stopAutoRotate()
  );
  if (value) {
    viewer.controls.minAzimuthAngle = -Infinity;
    viewer.controls.maxAzimuthAngle = Infinity;
  } else {
    const { cameraSetting } = sceneData;
    if (!cameraSetting) return;
    viewer.controls.minAzimuthAngle = cameraSetting.azimuthAngleLimit[0];
    viewer.controls.maxAzimuthAngle = cameraSetting.azimuthAngleLimit[1];
  }
});
const showWireMode = ref(false);
watch(showWireMode, (value) => {
  if (animationController.value.animationParam.playing && value)
    animationController.value.stop();
  if (value && enableDrag.value) enableDrag.value = false;
  requestAnimationFrame(() => model.applyWireFrame(value));
});
const showDimension = ref(false);
watch(showDimension, (value) => {
  if (animationController.value.animationParam.playing && value)
    animationController.value.stop();
  if (value) {
    if (dimension) {
      if (enableDrag.value && value) enableDrag.value = false;
      requestAnimationFrame(() =>
        model.showDimension(...dimension, sceneData.sizeColor)
      );
    } else {
      ElMessage.warning("该模型无对应尺寸信息");
    }
  } else if (dimension) {
    model.hideDimension();
  }
});
var explodScale = ref(0),
  disableExplode = ref(false);
watch(explodScale, (value) => {
  model.setExploding(value / 100);
});
var enableDrag = ref(false);
watch(enableDrag, (value) => {
  if (value) {
    explodScale.value = 0;
    autoRotate.value = false;
    showWireMode.value = false;
    showDimension.value = false;
    viewer.operator.enableDrag = true;
  } else {
    viewer.operator.restore();
    viewer.operator.enableDrag = false;
  }
});
const acitveSty = ref("default");
const action = (type) => {
  // stop()
  switch (type) {
    case "drag":
      enableDrag.value = !enableDrag.value;
      if (enableDrag.value) {
        isShowRightDialog.value = false;
        buildData.value = [];
      }
      break;
    case "restore":
      acitveSty.value = "restore";
      if (explodScale.value > 0) {
        explodScale.value = 0;
      }
      setTimeout(() => viewer.operator.restore(), 0);
      break;
    case "hide":
      acitveSty.value = "hide";
      viewer.operator.hide();
      break;
    case "showAlone":
      acitveSty.value = "showAlone";
      viewer.operator.showAlone();
      break;
    case "showAll":
      acitveSty.value = "showAll";
      viewer.operator.showAll();
      break;
    case "transparent":
      acitveSty.value = "transparent";
      viewer.operator.transparent();
      break;
  }
  setTimeout(() => {
    acitveSty.value = "default";
  }, 80);
};
onMounted(() => {
  initViewer(container);
  showPassword.value?'':loadModel();
});
function initCameraSetting(model) {
  const baseScaleValue = model.boundingBox.getSize(new AMRT.Vector3()).length();
  const { cameraSetting } = sceneData;
  if (cameraSetting.autoScaleLimit) {
    viewer.controls.minDistance =
      (cameraSetting.scaleLimit[0] / 100) * baseScaleValue;
    viewer.controls.maxDistance =
      (cameraSetting.scaleLimit[1] / 100) * baseScaleValue;
  } else {
    viewer.controls.minDistance = 0;
    viewer.controls.maxDistance = Infinity;
  }
  viewer.controls.minPolarAngle = cameraSetting.polarAngleLimit[0] + 90;
  viewer.controls.maxPolarAngle = cameraSetting.polarAngleLimit[1] + 90;
  viewer.controls.minAzimuthAngle = cameraSetting.azimuthAngleLimit[0];
  viewer.controls.maxAzimuthAngle = cameraSetting.azimuthAngleLimit[1];
}
</script>
<style lang="scss" scoped>
.password_box {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: #000000;
  z-index: 99;
  .password_content {
    padding: 20px;
    position: absolute;
    width: 350rem;
    border-radius: 12px;
    background: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .title {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: #52565e;
  }
  .password_input {
    margin: 30px 0;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    padding: 0 10px;
    box-sizing: border-box;
  }
  .password_btn {
    text-align: center;
    :deep(.el-button) {
      width: 140px !important;
    }
  }
}
.cover {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  span {
    width: 100rem;
    height: 100rem;
    cursor: pointer;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("~@/assets/img/play-btn.png");
  }
  span:hover {
    background-image: url("~@/assets/img/play-btn-active.png");
  }
}
.logo {
  position: fixed;
  top: 20px;
  left: 20px;
  width: 100px;
  pointer-events: none;
}
.side_menu_wrap {
  width: 165rem;
  height: 100vh;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  transition: all 0.4s;
  .side_menu {
    width: 100%;
    height: 100%;
    padding: 0 10%;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-width: none;
  }
  .side_menu::-webkit-scrollbar-track {
    display: none;
  }
  .row {
    margin: 20rem 0;
    display: flex;
    justify-content: space-between;
  }
  .label_row {
    margin: 20rem 0 10rem 0;
    display: flex;
    justify-content: space-between;
  }
  .btns {
    border-radius: 4rem;
    overflow: hidden;
    span {
      display: inline-block;
      width: 33.3%;
      height: 24rem;
      background-color: #fff;
      color: #182c49;
      text-align: center;
      line-height: 24rem;
      cursor: pointer;
      font-size: 12rem;
    }
    .l {
      width: 50%;
    }
    .active {
      background-color: #1458ea;
      color: #fff;
    }
  }
  .items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    span {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      padding: 10rem 0;
      .iconfont {
        width: 36rem;
        height: 36rem;
        color: #fff;
        font-size: 18rem;
        line-height: 36rem;
        text-align: center;
        border-radius: 18rem;
        background-color: rgba(0, 0, 0, 0.2);
      }
      b {
        display: block;
        width: 36rem;
        text-align: center;
        margin-top: 5px;
      }
    }
    span:nth-child(2),
    span:nth-child(5) {
      margin: 0 10rem;
    }
    .active {
      .iconfont {
        background-color: #1458ea;
      }
    }
  }
  .switch {
    position: absolute;
    top: 50vh;
    right: -20rem;
    z-index: 999;
    transform: translateY(-40rem);
    width: 20rem;
    height: 80rem;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
    line-height: 80rem;
    border-radius: 0 4rem 4rem 0;
    cursor: pointer;
    transition: all 0.4s;
    .iconfont {
      color: #fff;
      font-size: 12rem;
    }
  }
}
.a_controls {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 7;
  transition: all 0.4s;
  .p_wrapper {
    width: 100%;
    height: 2px;
    margin-bottom: 10rem;
    background-color: #7a7a7a;
    cursor: pointer;
    .p_inner {
      height: 100%;
      background-color: #fff;
      text-align: right;
      span {
        width: 8px;
        height: 8px;
        display: inline-block;
        background-color: #fff;
        border-radius: 4px;
        position: relative;
        top: -9px;
        right: -2px;
      }
      span:hover {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        top: -9px;
        right: -6px;
        background-color: #fff;
        background-clip: padding-box;
        border: 5px solid rgba(255, 255, 255, 0.2);
      }
      .active {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        top: -9px;
        right: -6px;
        background-color: #fff;
        background-clip: padding-box;
        border: 5px solid rgba(255, 255, 255, 0.2);
      }
      .time {
        position: absolute;
        left: -20px;
        background-color: rgba(0, 0, 0, 0.5);
        color: #fff;
        bottom: 20rem;
        padding: 5px 0;
        width: 48px;
        text-align: center;
        border-radius: 3px;
      }
      .time::after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border: 6px solid;
        border-left-color: transparent;
        border-right-color: transparent;
        border-top-color: rgba(0, 0, 0, 0.5);
        border-bottom-color: transparent;
        position: absolute;
        bottom: -12px;
        left: 19px;
      }
    }
  }
  .p_wrapper:hover {
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.3);
  }
  .btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10rem 15rem 10rem;
    color: #fff;
    /deep/ .select {
      margin: 0 10rem;
    }
    /deep/ .el-input__inner {
      background-color: rgba(100, 100, 100, 0.7);
      padding-right: 10px;
      width: 80rem;
      height: 26px;
      color: #fff;
      border: none;
      border-radius: 15px;
      border: none !important;
    }
    /deep/ .el-input__inner:focus {
      margin-top: -1px;
      height: 27px;
    }
    .l {
      display: flex;
      align-items: center;
      .iconfont {
        cursor: pointer;
        font-size: 18rem;
      }
      .icon-xiayige {
        margin-left: 20px;
        font-size: 16rem;
      }
      .animation_controls {
        position: relative;
        .current {
          margin-left: 10rem;
          height: 30rem;
          line-height: 30rem;
          padding: 0 10rem;
          border-radius: 2px;
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          .n {
            display: block;
            margin: 0;
            padding: 0 10px 0 0;
            max-width: 100rem;
            white-space: nowrap;
            overflow: hidden;
          }
          .iconfont {
            font-size: 8px;
          }
        }
        .active {
          background-color: rgba(20, 20, 20, 0.75);
        }
        .popper_wrapper {
          position: absolute;
          left: 10rem;
          bottom: 15rem;
          padding: 20rem 0;
          z-index: 99;
        }
        .popper {
          width: 194rem;
          background-color: rgba(20, 20, 20, 0.75);
          z-index: 2;
          border-radius: 5rem;
          padding: 1rem 0 10rem 0;
          .v {
            display: flex;
            margin-top: 5px;
            border-radius: 5px;
            justify-content: center;
            overflow: hidden;
            li {
              height: 28rem;
              text-align: center;
              line-height: 28rem;
              color: #fff;
              background-color: rgba(0, 0, 0, 0.2);
              font-size: 12rem;
              cursor: pointer;
            }
          }
          .a {
            padding: 0 6rem;
            li {
              width: 100%;
            }
          }
          .b {
            li {
              width: 45.5rem;
            }
          }
          .c {
            li:nth-child(1) {
              margin-top: 5px;
            }
            li {
              margin: 0 6px 0 6px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;
              padding: 5px 0;
            }
            li:hover {
              background-color: rgba(255, 255, 255, 0.1);
            }
          }
          .active {
            background-color: #1458ea !important;
          }
        }
      }
      .p1 {
        padding: 8rem;
      }
      .p1:hover {
        background-color: rgba(20, 20, 20, 0.75);
        border-radius: 50%;
      }
      span:hover {
        text-shadow: 0 0 5px rgba(20, 20, 20, 0.75);
      }
      .p2 {
        margin: 0 10rem;
      }
    }
    .r {
      min-width: 330rem;
      margin-left: 20rem;
      span {
        margin-right: 10px;
      }
      b {
        margin-right: 10px;
        font-size: 12px;
        font-weight: normal;
      }
    }
  }
}
.plane {
  position: fixed;
  right: 20rem;
  top: 20rem;
  /deep/ .el-input {
    width: 150rem;
  }
  /deep/ .el-input__inner {
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    border: none;
  }
  /deep/ .el-input__inner:focus {
    border: none !important;
  }
  /deep/ .el-input__inner::placeholder {
    color: #fff;
  }
  /deep/ .el-input.is-focus .el-input__inner {
    border: none !important;
  }
}
.active-sty {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
/deep/ .el-slider__button {
  border: 2px solid #ffffff;
  background: #1458ea;
}
/deep/ .el-slider__runway.disabled .el-slider__button {
  border-color: #ffffff;
  background: #c5c5c5;
}
/deep/ .el-slider__runway {
  background-color: #c5c5c5;
}
.dialog_right {
  width: 100%;
  left: 0;
  position: fixed;
  z-index: 100;
  bottom: 0;
  .title_right {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 12rem;
    color: #fff;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
  }
  .content_box {
    width: 100%;
    min-height: 140rem;
    max-height: 248px;
    background: rgba(0, 0, 0, 0.7);
    overflow-y: auto;

    > div {
      width: 100%;
      height: 100%;
      .title {
        height: 36px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background-color: rgba(127, 127, 127, 0.18);
        color: #f0f0f0;
        padding: 0 12px;
        .iconfont {
          font-size: 14px;
          margin-right: 4px;
          color: #ccc;
        }
      }
      .item_box {
        margin-bottom: 1px;
        > div > div {
          box-sizing: border-box;
          overflow: hidden;
          > i,
          > span {
            cursor: pointer;
            color: #fff;
            user-select: none;
            margin-right: 5px;
            white-space: nowrap;
          }
        }
        .content {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          min-height: 24rem;
          padding: 6rem 0;
          border-bottom: 1px solid rgba(127, 127, 127, 0.2);
          width: calc(100% - 28px);
          margin: 0 auto;
          > div {
            background-color: transparent;
            font-size: 13px;
            display: inline-block;
            width: 48%;
            box-sizing: border-box;
            box-sizing: border-box;
            color: #777777;
            word-break: break-all;
            user-select: none;
          }
          > div:first-child {
            margin-right: 20px;
          }
          > div:last-child {
            color: #cccccc;
          }
        }
        .content:last-child {
          border-bottom: none;
        }
        .top_b {
          border-top: 10px;
        }
        .bottom_b {
          border-bottom: 10px;
        }
        .left_b {
          border-left: 10px;
        }
        .right_b {
          border-right: 10px;
        }
      }
    }
  }
}
.plans {
  position: fixed;
  right: 0;
  top: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 12;
  ul {
    display: flex;
    flex-direction: column;
    width: 100rem;
    align-items: center;
    position: absolute;
    right: 0;
    transition: right 0.5s;
    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 90rem;
      .img {
        width: 50rem;
        height: 50rem;
        border-radius: 50%;
        cursor: pointer;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        transition: all 0.2s linear;
      }
      p {
        width: fit-content;
        height: 24rem;
        line-height: 24rem;
        background-color: rgba(39, 40, 48, 0.5);
        color: #fff;
        border-radius: 12rem;
        text-align: center;
        margin-top: 2rem;
        display: none;
        padding: 0 10rem;
      }
      .selected {
        border: 1rem solid #fff;
        border-radius: 50%;
        width: 60rem;
        height: 60rem;
        transition: all 0.2s linear;
      }
    }
    li:hover {
      p {
        display: block;
      }
    }
    .icon {
      width: 24rem;
      height: 24rem;
      text-align: center;
      line-height: 24rem;
      background-color: rgba(0, 0, 0, 0.5);
      cursor: pointer;
      border-radius: 4rem;
      .iconfont {
        color: #f6f6f6;
        font-size: 14rem;
      }
    }
    .default {
      width: 50rem;
      height: 50rem;
      line-height: 50rem;
      background-color: rgba(27, 28, 35, 0.5);
      border-radius: 50%;
      margin-bottom: 20rem;
      cursor: pointer;
      color: #f1f1f1;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.2s linear;
    }
    .selected {
      border: 1rem solid #fff;
      border-radius: 50%;
      width: 60rem;
      height: 60rem;
      transition: all 0.2s linear;
    }
  }
  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  .tip {
    width: 32rem;
    height: 77rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    color: #f6f6f6;
    right: 0;
    cursor: pointer;
    border-radius: 4px 0px 0px 4px;
    span {
      text-align: center;
      font-size: 11rem;
      margin-bottom: 5rem;
      b {
        font-size: 14rem;
        font-weight: normal;
      }
    }
    .iconfont {
      font-size: 14rem;
    }
  }
}

.fixd_icon {
  position: fixed;
  top: 8px;
  right: 8px;
  z-index: 999;
  cursor: pointer;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  border-radius: 50%;
  .iconfont {
    font-size: 18px;
    text-shadow: 0 0 1px #000000;
    color: #ffffff;
  }
}
.active_state {
  background: rgba(27, 28, 35, 0.7) !important;
}
.flex_ct {
  height: 120px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #aaaaaa;
  font-size: 13px;
  .iconfont {
    margin-bottom: 18px;
    font-size: 20px;
  }
}
/deep/ .animation_controls {
  display: block !important;
  transform: scale(0.75);
}
.share_btn {
  position: fixed;
  right: 20rem;
  top: 20rem;
  height: 40rem;
  display: flex;
  align-items: center;
  z-index: 1;
  .iconfont {
    color: #fff;
    font-size: 20rem;
  }
  span:nth-child(2) {
    width: 40rem;
    height: 40rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #2b57dd;
    border-radius: 20rem;
    margin-left: 20rem;
    img {
      width: 30rem;
    }
  }
}
.author {
  position: fixed;
  left: 20rem;
  top: 20rem;
  display: flex;
  width: 300rem;
  overflow: hidden;
  z-index: 1;
  img {
    width: 40rem;
    height: 40rem;
    margin-right: 10rem;
    border-radius: 20rem;
  }
  p {
    span {
      display: block;
      white-space: nowrap;
      line-height: 20rem;
      color: #fff;
      width: 160rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
<style lang="scss">
.el-slider__button {
  background-color: #1458ea;
  width: 18px;
  height: 18px;
  border-color: #fff;
}
.el-slider__bar {
  background-color: #1458ea;
}
.comm-animation{
  justify-content: flex-end;
}
</style>
